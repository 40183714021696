import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In honor of Valentine’s Day, `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`we`}</a>{` took a look at the difference in usage between men and women on Facebook Messenger bots over the past two months.`}</p>
    <h3>{`Who Uses Bots More? Men or Women?`}</h3>
    <p>{`On average about 63% of bot users are men and 28% are women, with the remaining 9% unknown, or undetected.`}</p>
    <p>{`Men tend to be more engaged than women in terms of sessions per user per month — on average they have about 50% more sessions. Women however tend to message more per session — about 12% more messages in each session.`}</p>
    <p>{`In regards to the number of bots men and women use, while the vast majority of users have only used one bot, about 14% of men and 10.6% of women use more than one bot.`}</p>
    <h3>{`A Picture Says a Thousand Words`}</h3>
    <p>{`We examined images sent in to Facebook bots to see if there are any differences between men and women. We ran the images through Amazon’s Rekognition API for image detection, which detects labels and a confidence level. Below are the top labels detected with confidence levels over 90%.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132222/0_dnUfphsbLZYKEQNH.png)
    </figure>
While bot men and women send selfies to bots, women tend to skew a bit more to
sending selfies. The labels Person, Human, People, and Portrait are all detected
in selfies. One thing that was interesting is the label “Electronics” — why are
women taking pictures of electronics? Do you know why that is? It turns out it’s
when a selfie is taken in the mirror, the photo contains an image of the phone,
which triggers the “Electronics” label. Guys tend to take more photos of cars
and motorcycles than women which is why you see Vehicle and Car as well.
    <h3>{`Speaking of Selfies`}</h3>
    <p>{`Another interesting takeaway from looking at the images sent in to bots is from looking at the NSFW images — our poor eyes!`}</p>
    <p>{`It turns out both men and women send NSFW images to bots, including naked selfies, however it’s predominantly men. About 90% of the NSFW images come from men.`}</p>
    <p>{`Luckily, the overall percentage of NSFW images is quite low — only about 2.5% of images are NSFW, and only about 6% of bots receive them.`}</p>
    <p>{`The interesting thing is even though the percentage of users and images is quite low, when someone does send an NSFW image, they send it a lot! The average NSFW image is sent almost 5 times, compared to once for a “normal” non-NSFW image. In fact some users have even sent the same NSFW image over 100 times! Apparently once you get over the hurdle of sending naked selfies, you’re ok sending them a lot.`}</p>
    <h3>{`Bots, How do We Love Thee`}</h3>
    <p>{`Lastly we looked at user sentiment to see if there are any differences between men and women.`}</p>
    <p>{`It turns out, on average, per bot, the sentiment for men and women is about the same — how they feel about a bot, whether positive or negative, tends to be about the same.`}</p>
    <p>{`Since this is Valentine’s day, and love is in the air, we took a look at how many users express their love for the bots they’re using. It turns out, women are a bit more likely, 1.4% to ~1%, to tell the bots they’re using that they love it!`}</p>
    <h3>{`About Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we have bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Assistant, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      